.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  &:first-child {
    justify-content: flex-start;
  }
  &:last-child {
    justify-content: flex-end;
  }
}

.firstCol {
  padding: 10px;
  border-right: 2px solid #575A65;
  color: var(--color-main);
  text-align: right;

  h6 {
    margin: 0px;
    margin-bottom: 5px;
    font-size: 20px;
  }

  h2 {
    font-size: 40px;
    margin: 0px;
  }
}

.secondCol {
  padding: 5px;
  padding-left: 15px;
  color: #575A65;

  h5 {
    margin: 0px;
    margin-bottom: 5px;
    font-size: 25px;
    font-family: var(--font-semi-bold);
  }

  p {
    margin: 0px;
    margin-bottom: 5px;
  }
}