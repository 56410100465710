

.logo {
  width: 13.75vw;
  height: auto;

  @media screen and (max-width: 768px) {
    width: 150px;
  }
}

.absolute_container {
  position: absolute;
  top: 0px;
  right: 0px;

  &.bottom {
    bottom: 0px;
    top: auto;
  }
}