.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog {
    background-color: white;
    border-radius: 15px;
    width: 50%;

    @media screen and (max-width: 480px) {
      width: 80%;
    }

    .dialogHeader {
      height: 50px;
      border-bottom: 3px solid #132744;
    }
    .dialogBody {
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #132744;

      p {
        font-size: 18px;
        margin-top: 0px;
        margin-bottom: 20px;
      }
      button {
        background-color: #FFCB00;
        padding: 10px 15px;
        outline: none;
        border: none;
        border-radius: 10px;
        width: 230px;
        font-size: 20px;
        cursor: pointer;
        color: #132744;
      }

    }
  }
}