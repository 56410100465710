.heading {
  font-size: 25px;
  width: 100%;
  text-align: center;
  color: var(--color-second);
  font-family: var(--font-bold);
  margin-top: 0px;
  

  @media screen and (min-width: 651px) {
    transform: translateY(-100%);
  }

  @media screen and (min-width: 1200px) {
    font-size: 45px;
  }

  @media screen and (min-width: 1500px) {
    font-size: 75px;
  }
}

.headerVideo {
  border: 1px solid #2D2BE0;
  outline: none;
  background-color: #2D2BE0;
}

.start {
  position: absolute;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    color: white;
    font-size: 18px;
    text-align: center;
    transform: translateX(-3px);
    @media screen and (min-width: 1200px) {
      font-size: 25px;
    }
    @media screen and (min-width: 1500px) {
      font-size: 30px;
    }
  }
  

  img {
    width: 30px;
    height: auto;
    margin-top: 12px;

    @media screen and (max-width: 480px) {
      width: 20px;
    }
    @media screen and (min-width: 1500px) {
      width: 40px;
    }
  }
}

.whiteRowInfo {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }

  
  div {
    flex: 1;
    display: flex;

    &:first-child {
      p {
        color: var(--color-main);
        width: 70%;
        font-size: 20px;
        font-family: var(--font-bold);
      }
    }

    &:last-child {
      justify-content: flex-end;
      img {
        width: 75%;
        height: auto;
      }
    }
  }
  
}

.whiteRowCurrency {
  margin-top: 80px;
  width: 300px;
  color: var(--color-main);
  p svg {
    transform: translateY(4px);
    margin-right: 7px;
    margin-left: 10px;
  }
}

.selectCountriesInstruction {
  font-family: var(--font-bold);
  font-size: 20px;
  width: 100%;
  color: var(--color-second);
  margin: 0px;
  margin-bottom: 20px;

  @media screen and (min-width: 1200px) {
    font-size: 30px;
  }

  @media screen and (min-width: 1500px) {
    font-size: 40px;
  }
}

.decor_bottom {
  width: 86%;
  margin-left: 7%;
  margin-top: 20px;
  height: auto;
}

.selectCountriesRow {
  margin-top: 70px;
  height: 563px;
  box-sizing: border-box;
  @media screen and (min-width: 1200px) {
    height: 453px;
  }
  @media screen and (min-width: 1500px) {
    height: 563px;
  }
  display: flex;

  .selectCountriesRowLeft {
    flex: 5;
    display: flex;

    &.full5 {
      border: 1px solid var(--color-second);
      background-color: var(--color-second);
      border-radius: 10px;
    }
  }
  .selectCountriesRowRight {
    width: 225px;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  
}

.generateReport {
  margin-top: 20px;
  border: none;
  outline: none;
  background-color: #FFCB00;
  height: 50px;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
}


.homeFormContainer {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  section {
    flex: 1;
    display: flex;
    flex-direction: column;

    .overview {
      font-size: 25px;
      color: var(--color-second);
      font-family: var(--font-bold);
      margin-bottom: 20px;
      @media screen and (min-width: 1200px) {
        font-size: 45px;
      }
      @media screen and (min-width: 1500px) {
        font-size: 75px;
      }
    }

    p {
      color: white;
      font-size: 22px;
    }

    // OVERVIEW
    &:first-child {
      justify-content: space-between;
    }
    // LAST CHILD
    &:last-child {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      & > div {
        width: 70%;
        box-sizing: border-box;
        svg {
          margin-right: 5px;
        }
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }


      form {
        border: 3px solid var(--color-second);
        padding: 30px;
        border-radius: 10px;
        width: 100%;
        background-color: rgba(45,43,224,0.9);
        box-sizing: border-box;

        @media screen and (max-height: 720px) {
          padding: 10px 20px;
        }

        label {
          color: white;
          display: block;
        }

        input {
          border-radius: 9px;
          width: 100%;
          padding: 12px;
          box-sizing: border-box;
          border: none;
          outline: none;
          margin-top: 6px;
        }

        .twoColumns {
          margin-bottom: 20px;
          display: flex;
          flex-direction: row;
          & > div {
            flex: 1;
          }
          & > div:nth-child(2) {
            margin-left: 20px;
          }
        }

        .twoColumnsOneThird {
          margin-bottom: 20px;
          display: flex;
          flex-direction: row;
          & > div {
            flex: 3;
          }
          & > div:nth-child(2) {
            flex: 1;
            margin-left: 20px;
          }
        }

        .twoColumnsAnyOne {
          margin-bottom: 20px;
          display: flex;
          width: 100%;
          flex-direction: row;
          align-items: flex-start;

          input[type="checkbox"] {
            width: 20px;
            height: 20px;
            margin-right: 20px;
            // border-radius: 4px;
            clip-path: circle(60% at 50% 50%);
          }

          & > span {
            flex: 1;
          }

          a {
            color: white;
          }
        }

        @media screen and (max-height: 720px) {
          .twoColumnsAnyOne,
          .twoColumns,
          .twoColumnsOneThird {
            margin-bottom: 5px;
          }
        }

        .btnRow {
          display: flex;
          justify-content: center;

          button {
            background-color: #FFCB00;
            padding: 10px 15px;
            outline: none;
            border: none;
            border-radius: 10px;
            width: 50%;
            font-size: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.copyrightContainer {
  p {
    font-size: 11.5px !important;
  }
}