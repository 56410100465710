// define color variable
:root {
  --color-main: #2726DA;
  --color-second: #26D7DA;
  --color-dark: #132744;
  --font-regular: 'Neue-Plak-Text', Helvetica, sans-serif;
  --font-bold: 'Neue-Plak-Text-Bold', Helvetica, sans-serif;
  --font-semi-bold: 'Neue-Plak-Text-SemiBold', Helvetica, sans-serif;
}

html, body, #root {
  font-family: 'Neue-Plak-Text', Helvetica, sans-serif;
  padding: 0;
  margin: 0;
  height: 100%;
  box-sizing: border-box;
}

$breakpoints: (
  576,
  768,
  992,
  1200,
  1400,
  1920,
);

*::-webkit-scrollbar {
  width: 0.5em;
}
 
*::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}
 
*::-webkit-scrollbar-thumb {
  background-color: transparent;
  // outline: 1px solid slategrey;
}