.container {
  border: 2px solid var(--color-main);
  border-radius: 15px;
  color: #575A65;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  margin-bottom: 50px;
  margin-top: 10px;
  position: relative;
  width: 90%;
  margin-left: 5%;

  .content {
    flex: 5;
    padding: 40px;
    @media screen and (max-height: 700px) {
      padding: 20px;
    }
  }
  .thumb {
    flex: 1;
    position: relative;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    div {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0,0,0,0.5);
    }
  }

  h3 {
    font-size: 40px;
    color: var(--color-main);
  }

  .row1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    .value {
      margin-left: 10%;
      display: flex;
      align-items: center;

      h4 {
        color: var(--color-second);
        font-size: 40px;
        font-family: var(--font-bold);
        margin-top: 0px;
        margin-bottom: 0px;
        @media screen and (min-width: 1200px) {
          font-size: 45px;
        }
        @media screen and (min-width: 1500px) {
          font-size: 75px;
        }
      }
      p {
        margin-left: 20px;
        width: 200px;
      }
    }
  }

  .row2 {
    display: flex;
    justify-content: space-between;

    > div {
      width: 25%;
    }
  }
}