.container {
  flex: 1;
  position: relative;
  padding: 30px 10px;
  box-sizing: border-box;

  p {
    margin-top: 0px;
    margin-bottom: 30px;
    font-size: 14px;
  }
}

.inputContainer {
  position: relative;
  input {
    border: none;
    outline: none;
    height: 40px;
    background-color: var(--color-dark);
    border-radius: 10px;
    display: block;
    width: calc(100%);
    box-sizing: border-box;
    padding: 5px 10px;
    padding-left: 40px;
    color: white;
    margin-bottom: 30px;
  }

  span {
    position: absolute;
    top: 50%;
    left: 7px;
    transform: translateY(-14px);
    color: white;
  }
}

.instructionText {
  position: absolute;
  color: white;
  font-family: var(--font-bold);
  left: 10px;
  top: -40px;
  display: flex;
  align-items: center;
  font-size: 14px;

  svg {
    transform: translateY(15px);
    margin-right: 10px;
    width: 26px;
    height: 24px;
  }
}