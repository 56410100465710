.container {
  display: flex;
  flex-direction: column;
}

.countryThumbContainer {
  display: flex;
  height: 80px;
  margin-bottom: 20px;

  .thumb {
    width: 100px;
    height: 80px;
    position: relative;

    img {
      position: absolute;
      width: 100px;
      height: 130px;
      left: 0;
      bottom: 0;
      border-radius: 10px;
    }

    div {
      position: absolute;
      width: 100px;
      height: 130px;
      left: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.5);
      border-radius: 10px;
    }
  }

  .headingContainer {
    display: flex;
    align-items: flex-end;
  }

  .heading {
    margin-left: 25px;
    font-size: 25px;
    color: var(--color-second);
    font-family: var(--font-bold);
    margin-top: 0px;
    margin-bottom: 0px;
    transform: translateY(11px);
  
    @media screen and (min-width: 1200px) {
      font-size: 45px;
    }
  
    @media screen and (min-width: 1500px) {
      font-size: 75px;
    }
  }

  @media screen and (min-width: 1500px) {
    height: 100px;
    .thumb {
      height: 100px;
      width: 120px;
      img, div {
        width: 120px;
        height: 200px;
      }
    }
    .heading {
      transform: translateY(15px);
    }
  }
}

.mainDetails {
  display: flex;
  align-items: stretch;
  height: 65vh;

  p {
    color: white;
    margin-bottom: 10px;
    svg {
      margin-right: 5px;
    }
  }
}

.inputRevenue {
  flex: 1;
  padding-right: 30px;
  box-sizing: border-box;
}

.selectPaymentMethods {
  flex: 3; 
  display: flex;
  flex-direction: column;
}

.continue {
  display: flex;
  align-items: center;
  .continueBtn {
    margin-left: 40px;
  border: none;
  outline: none;
  background-color: #FFCB00;
  height: 40px;
  border-radius: 10px;
  padding: 5px 45px;
  font-size: 20px;
  cursor: pointer;
  }
}

.inputContainer {
  position: relative;
  input {
    border: none;
    outline: none;
    height: 40px;
    border: 2px solid var(--color-second);
    background-color: transparent;
    border-radius: 10px;
    display: block;
    width: calc(100%);
    box-sizing: border-box;
    padding: 5px 10px;
    padding-left: 40px;
    color: white;
    margin-bottom: 30px;
  }

  span {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-14px);
    color: white;
  }
}

.creditCardAndPaymentMethods {
  border: 2px solid var(--color-second);
  border-radius: 10px;
  background-color: rgba(19, 39, 68, .85);
}