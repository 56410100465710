.container {
  flex: 3;
  background-color: var(--color-dark);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 30px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  overflow-y: hidden;

  & > div {
    flex: 1;
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
    flex-direction: column;

    & >  div {
      flex: 1;
    }

    p {
      color: #26D7DA;
      margin-top: 0px;
    }

    ul {
      margin-left: 0;
      padding-left: 0;
    }

    li {
      list-style-type: none;
      color: white;
      margin-bottom: 6px;
      width: 77%;
      padding: 2px 8px;
      padding-bottom: 5px;
      cursor: pointer;

      &.selected {
        background-color: var(--color-second);
        color: var(--color-dark);
        border-radius: 4px;
      }
    }
  }
}

