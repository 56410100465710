.svgAnimation {
  .arrow1, .arrow2 {
    transition: all 0.3s;
  }
  &:hover {
    .arrow1, .arrow2 {
      transform: translateY(5px);
    }
  }
  
}