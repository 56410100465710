
.descriptionContainer {
  color: white;
  width: 80%;
  box-sizing: border-box;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.legendECommerce {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: white;
  margin-right: 20px;
  margin-bottom: -3px;
}
.legendMCommerce {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #26D7DA;
  margin-right: 20px;
  margin-bottom: -3px;
}
.legendMCommerce, .legendECommerce {
  
}

.percentageCommerceContainer {
  text-align: right;
  p {
    margin-bottom: 0px;
    // margin-left: 40px;
  }
}

.pEcommerce {
  font-size: 30px;
  font-family: var(--font-bold);
  color: white;
}
.pMcommerce {
  font-size: 30px;
  font-family: var(--font-bold);
  color: var(--color-second);
}