
.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.heading {
  margin-left: 25px;
  font-size: 25px;
  color: var(--color-second);
  font-family: var(--font-bold);
  margin-top: 0px;
  margin-bottom: 0px;
  transform: translateY(11px);

  @media screen and (min-width: 1200px) {
    font-size: 45px;
  }

  @media screen and (min-width: 1500px) {
    font-size: 75px;
  }
}

.reportRow {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10vh;

  .item {
    width: 12vw;
    height: 32vh;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    .thumb {
      width: 100%;
      height: 100%;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom;
      }
      div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0,0,0,0.5);
        transition: all 300ms;

        &:hover {
          background-color: rgba(0,0,0,0.1);
        }
      }
    }

    .name {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #FFCB00;
      border-radius: 10px;
      color: var(--color-dark);
      text-align: center;
      font-size: 20px;

      @media screen and (min-width: 1500px) {
        font-size: 25px;
      }
    }
  }
}