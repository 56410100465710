.bgContainer {
  
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;

  @media screen and (min-width: 1400px) {
    background-position: 0% 200%;
  }
}

.heading {
  margin-top: 5vh;
  margin-left: 25px;
  font-size: 25px;
  color: var(--color-second);
  font-family: var(--font-bold);
  margin-bottom: 0px;
  transform: translateY(11px);

  @media screen and (min-width: 1200px) {
    font-size: 45px;
  }

  @media screen and (min-width: 1500px) {
    font-size: 75px;
  }
}

.downloadContainer {
  display: flex;
  align-items: center;
  padding: 25px;

  p {
    color: white;
    svg {
      margin-right: 5px;
    }
  }

  .countryList {
    width: 20vw;
    min-height: 350px;
    padding: 17px;
    box-sizing: border-box;
    border: 2px solid var(--color-second);
    border-radius: 20px;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1500px) {
      padding: 22px;
    }

    .item {
      width: 100%;
      padding: 2.5px 7.5px 5px 7.5px;
      margin: 2.5px;
      box-sizing: border-box;
      color: white;
      font-size: 16px;
      cursor: pointer;
      user-select: none;
      position: relative;

      &.selected {
        border-radius: 8px;
        background-color: var(--color-second);
        color: #132744;
      }
      .deselectItemIcon {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        padding: 2.5px;

      }
    }
  }
}

.btn {
  margin-left: 5vw;
  margin-right: 5vw;
  display: flex;
  flex-direction: column;
  width: 15vw;
  box-sizing: border-box;

  button {
    margin: 10px;
    height:  40px;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 4px 16px;
    border-radius: 9px;
    width: 100%;
    cursor: pointer;
    background-color: #FFCB00;
    color: #132744;
    font-size: 20px;
    box-sizing: border-box;
  }

  @media screen and (min-width: 1500px) {
    font-size: 25px;
  }

  .download {
   
  }
  .starAgain {
    background-color: transparent;
    border: 2px solid #FFCB00;
    color: #FFCB00;
  }
}