.container {
  position: relative;
  width: 100%;
}

.instructionText {
  position: absolute;
  color: white;
  font-family: var(--font-bold);
  left: 10px;
  top: -40px;
  display: flex;
  align-items: center;
  font-size: 14px;

  svg {
    transform: translateY(7px);
    margin-right: 10px;
    width: 26px;
    height: 24px;
  }
}

.countryBox {
  border: 1px solid var(--color-second);
  border-radius: 10px;
  min-height: 150px;
  padding: 10px;
  cursor: pointer;
}

.selectedCountry {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
  }
}