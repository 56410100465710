.container {
  flex: 1;
  border: 1px solid var(--color-second);
  border-radius: 10px;
  height: 100%;
  // padding-right: 10px;
  box-sizing: border-box;
  position: relative;
  background-color: var(--color-dark);
}

.country_item {
  margin: 10px;
  color: white;
  cursor: pointer;
  span {
    padding: 10px;
    display: flex;
    width: 70%;
    border-radius: 7px;
  }
  &.selected span {
    background-color: var(--color-second);
    color: var(--color-dark);
    padding: 10px;
  }
}

.trackScrollVertical {
  width: 10px;
  position: absolute;
  right: 8px;
  bottom: 2px;
  top: 8px;
  border-radius: 3px;
}

.thumbScrollVertical {
  background-color: var(--color-second);
  border-radius: 4px;
  width: 10px;
}

.instructionText {
  position: absolute;
  color: white;
  font-family: var(--font-bold);
  left: 10px;
  top: -40px;
  display: flex;
  align-items: center;
  font-size: 14px;

  svg {
    transform: translateY(7px);
    margin-right: 10px;
    width: 26px;
    height: 24px;
  }
}