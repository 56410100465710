.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.absoluteFill {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}
