

.thumbScrollVertical {
  background-color: var(--color-second);
  border-radius: 4px;
  width: 10px;
}

.trackScrollVertical {
  width: 7px;
  position: absolute;
  right: 2px;
  bottom: 2px;
  top: 8px;
  border-radius: 3px;
}

.thumbY {
  background: var(--color-dark) !important;
  // height: 40px !important;
  width: 8px !important;
  margin-left: 1px !important;
  margin-top: 1px !important;
  border-radius: 9px !important;
}

.trackY {
  background-color: var(--color-second) !important;
  width: 10px !important;
}