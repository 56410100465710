.heading {
  font-size: 45px;
  width: 100%;
  text-align: left;
  color: var(--color-second);
  font-family: var(--font-bold);
  margin-top: 30px;

  @media all and (min-width: 1199px) {
    font-size: 45px;
  }

  @media all and (min-width: 1500px) {
    font-size: 75px;
  }
}

.start {
  position: absolute;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);

  img {
    width: 30px;
    height: auto;
    margin-top: 12px;

    @media all and (max-width: 480px) {
      width: 20px;
    }
    @media all and (min-width: 1500px) {
      width: 40px;
    }
  }
}

.decorateBottom {
  position: absolute;
  width: 77vw;
  height: 70px;
  left: 50%;
  bottom: -110px;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  z-index: 1000;

  @media all and (min-width: 1200px) {
    bottom: -110px;
  }

  @media all and (min-width: 1500px) {
    bottom: -160px;
  }
}

.countryDropdownContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 220px;
}

.overviewRow1 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  h3 {
    width: auto;
    @media all and (max-height: 799px) {
      margin-top: 0px;
    }
  }

  img, video {
    width: 40%;
    height: auto;
    margin-top: 30px;
    @media all and (max-height: 799px) {
      margin-top: 0px;
    }
  }
}

.overviewRow2 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 70px;
  align-items: center;

  @media all and (max-width: 480px) {
    flex-direction: column;
  }

  > div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      margin-top: 20px;
      margin-bottom: 40px;
      color: white;
      text-align: center;
      padding: 0px 15%;
    }
  }

  .infoBox {
    color: white;
    border: 2px solid var(--color-second);
    border-radius: 30px;
    width: 80%;
    padding: 30px 10px;
    display: flex;
    align-items: center;

    > div {
      flex: 1;
      p {
        padding: 0px;
        margin: 0px;
      }

      span {
        font-size: 40px;
      }
    }
  }
}

.APMRow1 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  h3 {
    width: auto;
    color: var(--color-main);
    @media all and (max-height: 799px) {
      margin-top: 0px;
    }
  }

  img, video  {
    width: 40%;
    height: auto;
    margin-top: 30px;
    @media all and (max-height: 799px) {
      margin-top: 0px;
    }
  }
}

.APMRow2 {
  margin-top: 20px;
}

.APMText {
  color: #575A65;
  font-size: 18px;
}

.APMRow3 {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}


.APMRow4 {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .infoBox {
    width: 40%;
    color: var(--color-main);
    border: 2px solid var(--color-main);
    border-radius: 30px;
    padding: 20px;
    display: flex;
    align-items: center;

    > div {
      flex: 1;

      p {
        color: #575A65;
      }

      span {
        font-size: 40px;
      }
    }
  }

  .APMAdditionInfo {
    width: 40%;

    p {
      text-align: center;
    }

    .additionalContainer {
      display: flex;

      .additionalName {
        width: 50%;
        display: flex;
        justify-content: flex-end;
      }
      .additionalValue {
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        color: var(--color-main);
        font-family: var(--font-bold);
      }
    }
    
  }
}

.cardSchemeRow2 {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.cardSchemeRow2Info {
  margin-left: 40px;
  color: white;
  width: 40%;
  display: flex;
  flex-direction: column;
  

  p {
    text-align: left;
  }

  .item {
    span {
      display: inline-block;
      width: 120px;
      font-size: 40px;
      margin-right: 10px;
    }
  }

  .unsupport {
    margin-top: 30px;

    > div {
      display: flex;
      img {
        width: 100px;
        height: 70px;
      }
      .unsupportCard {
        margin-left: 20px;
        display: flex;
        .unsupportCardName {
          width: 150px;
          display: flex;
          justify-content: flex-start;
        }
        .unsupportCardValue {
          margin-left: 20px;
          display: flex;
          justify-content: flex-start;
          color: var(--color-second);
          font-family: var(--font-bold);
        }
      }
    }
  }
}

.commerceGrowRow1 {
  // h3 {
  //   text-align: left;
  // }
}

.chartDemo {
  width: 50%;
  height: auto;
  margin-left: 25%;
}

.instoreRow1 {
  h3 {
    text-align: left;
    color: var(--color-main);
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    color: #575A65;
    width: 200px;
    margin: 0px;
  }
}

.chartDemo2 {
  width: 70%;
  height: auto;
  margin-left: 15%;
}

.nextStep1 {
  h3 {
    text-align: left;
    margin-top: 40px;
  }
}

.nextStep2 {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-top: -15px;

  .nextStep2Left {
    width: 45%;
    height: 100%;

    p {
      font-size: 20px;
    }
  }

  .nextStep2Right {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .nextStep2RightIframe {
      width: 100%;
      padding-bottom: 56.25%;
      position: relative;
      div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }
    }

    img {
      width: 100%;
      height: auto;
    }

    .btn {
      width: fit-content;
      margin-top: 30px;
    }
  }
}

.btn {
  cursor: pointer;
  margin-top: 20px;
  border: none;
  outline: none;
  background-color: #FFCB00;
  // width: 130px;
  padding: 0px 55px;
  height: 50px;
  border-radius: 10px;
  font-size: 20px;
}

.nextStepArtwork {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 30%;
  height: auto;
}

.nextStep3 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    width: 30px;
    height: auto;
  }

  div {
    color: white;
    margin-left: 10px;
    p {
      margin: 0px;

      &:last-child {
        font-size: 30px;
      }
    }
  }
}


.countryImage {
  width: 23vw;
  height: 80vh;
  position: relative;
  border-radius: 10px;
  box-sizing: border-box;
  margin-left: 5vw;

  img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 23vw;
    height: 120vh;
    object-fit: cover;
    object-position: bottom;
    border-radius: 10px;
  }
  div {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 23vw;
    height: 120vh;
    background-color: rgba(0,0,0,0.4);
    border-radius: 10px;
  }
}
.headingSection {
  flex: 2;
  margin-left: 2.5vw;
  margin-right: 2.5vw;
  p {
    color: white;
    font-size: 25px;
    margin-bottom: 10px;
  }

  @media all and (min-width: 1500px) {
    p {
      font-size: 35px;
    }
  }
}
.headerArtworkSection {
  flex: 3;
  video {
    width: 100%;
    height: auto;
  }
}

.downloadContainer {
  position: fixed;
  top: 0px;
  right: 0px;
  border-bottom-left-radius: 15px;
  background-color: #FFCB00;
  padding: 10px;
  height: 55px;
  display: flex;
  align-items: center;
  z-index: 10000000;

  button {
    margin: 10px;
    height:  40px;
    outline: none;
    border: none;
    background-color: transparent;
    font-weight: bold;
    padding: 4px 16px;
    border-radius: 9px;
    cursor: pointer;
  }
  .downloadCopy {
    background-color: #132744;
    color: #FFCB00;
  }
  .back {
    border: 2px solid #132744;
    color: #132744;
  }
}

.copyrightContainer {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 50%;
  p {
    color: white;
    font-size: 11px !important;
  }

  @media screen and (max-height: 750px) {
    position: relative;
    margin-top: 20px;
    width: 50%;
  }
}