.container {
  width: 100%;
  min-height: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    padding: 40px 60px;
  }

  @media screen and (min-width: 1500px) {
    padding: 90px 140px;
  }

  &_inner {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}