.btnImport {
  background-color: var(--color-second);
  padding: 5px 15px;
  border: none;
  outline: none;
}

.previewContainer {
  width: 80%;
  color: var(--color-second);
  overflow: hidden;
  
  pre {
    height: 50%;
    overflow-y: auto;
  }
}

.mes {
  color: rgba(255,255,255,0.5);

  a {
    color: var(--color-second);
    font-weight: bold;
  }
}