
.selectCurrencyContainer {
  color: white;
  width: 320px;

  svg {
    margin-right: 5px;
  }
}

.selectCountriesRow {
  height: 563px;
  box-sizing: border-box;
  @media screen and (min-width: 1200px) {
    height: 453px;
  }
  @media screen and (min-width: 1500px) {
    height: 563px;
  }
  display: flex;
  flex-direction: column;

  .selectCountriesRowLeft {
    flex: 5;
    display: flex;

    &.full5 {
      border: 1px solid var(--color-second);
      background-color: var(--color-second);
      border-radius: 10px;
    }
  }
  .selectCountriesRowRight {
    width: 225px;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  
}

.decor_bottom {
  width: 86%;
  left: -2vw;
  right: 9vw;
  position: absolute;
  bottom: 0px;
  padding-bottom: 15%;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-height: 715px) {
    display: none;
  }
}

.selectCountryContainer {
  margin-top: 30px;
  p {
    color: white;
    svg {
      margin-right: 5px;
    }
  }
}

.listContainer {
  display: flex;
  align-items: center;

  .list {
    flex: 1;
    padding: 17px;
    box-sizing: border-box;
    border: 2px solid var(--color-second);
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;

    @media screen and (min-width: 1500px) {
      padding: 22px;
    }

    .item {
      width: 13.5%;
      padding: 2.5px 7.5px 7.5px 7.5px;
      margin: 2.5px;
      box-sizing: border-box;
      color: white;
      font-size: 16px;
      cursor: pointer;
      user-select: none;
      position: relative;

      @media screen and (min-width: 1500px) {
        margin: 7.5px;
        font-size: 18px;
        width: 13%;
      }

      &.selected {
        border-radius: 8px;
        background-color: var(--color-second);
        color: #132744;
      }
      .deselectItemIcon {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-63%);
        padding: 2.5px;

      }
    }
  }
}

.continueBtn {
  margin-left: 40px;
  border: none;
  outline: none;
  background-color: #FFCB00;
  height: 40px;
  border-radius: 10px;
  padding: 0px 45px;
  font-size: 20px;
  cursor: pointer;
}