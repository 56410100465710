#mktoForm_5975 {
  width: 100% !important;
  padding: 20px;
  position: relative;

  .mktoOffset {
    height: 10px !important;
  }

  .mktoForm .mktoError {
    z-index: 999999999 !important;
  }

  .mktoFieldDescriptor.mktoFormCol {
    margin-bottom: 10px !important;
  }

  .mktoFieldWrap {
    width: 100% !important;
    position: relative;
  }

  .mktoFormRow {
    width: 100% !important;
  }

  .Row_1 {
    fieldset {
      padding-right: 0px;
      margin-bottom: 0px;
      border: none;
      width: 100% !important;
    }
  }

  .Row_2 {
    transform: translateX(-10px);
  }

  .mktoAsterix {
    display: none;
  }


  label, .mktoAsterix {
    color: white !important;
    font-family: "Neue-Plak-Text", Helvetica, sans-serif !important;
    font-size: 16px !important;
    margin-bottom: 5px !important;
  }
  label {
    width: 100% !important;
    display: inline-block;

    a {
      color: white !important;
      text-decoration-line: underline !important;
    }
  }

  select,
  .mktoFieldWrap input[type="text"],
  .mktoFieldWrap input[type="tel"],
  .mktoFieldWrap input[type="email"] {
    border-radius: 10px !important;
    height: 40px !important;
  }
  .mktoButtonRow {
    display: flex;
    justify-content: center;
  }
  .mktoButtonWrap {
    margin-left: 0px !important;
  }

  .mktoFieldDescriptor.mktoFormCol {
    width: 100% !important;
    box-sizing: border-box;
  }

  select {
    width: 100% !important;
    margin-top: 5px;
    option {
      font-family: "Neue-Plak-Text", Helvetica, sans-serif !important;
    }
  }

  .mktoFieldWrap input[type="radio"],
  .mktoFieldWrap input[type="checkbox"] {
    width: auto !important;
  }

  .mktoRadioList {
    width: 100% !important;
  }

  .mktoRadioList label {
    width: auto !important;
    display: block;
  }

  .Row_12 .mktoFieldWrap,
  .Row_14 .mktoFieldWrap {
    display: flex;
    flex-direction: row-reverse;
  }

  .mktoFormRow.Row_12 > div.mktoFieldDescriptor.mktoFormCol > div.mktoFieldWrap > div.mktoLogicalField.mktoCheckboxList.mktoHasWidth.mktoValid,
  .mktoFormRow.Row_14 > div.mktoFieldDescriptor.mktoFormCol > div.mktoFieldWrap.mktoRequiredField > div.mktoLogicalField.mktoCheckboxList.mktoHasWidth.mktoRequired {
    width: auto !important;
  }

  .mktoFormRow.Row_12 > div.mktoFieldDescriptor.mktoFormCol > div.mktoFieldWrap > div.mktoLogicalField.mktoCheckboxList.mktoHasWidth {
    width: auto !important;
  }
}

.mktoForm select.mktoField {
  background-image: url('../const/assets/img/blue-arrow-dropdown.png') !important;
}

#selfIdentifiedSegment, #Country {
  background-image: url('../const/assets/img/blue-arrow-dropdown.png') !important;
}

#mktoForm_5975 .mktoErrorMsg {
  color: white !important;
}